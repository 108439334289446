<template>
  <div class="page page-box page-padding">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="班级名称：">
          <template>
            <el-select v-model="listQuery.ClassName" filterable clearable remote reserve-keyword placeholder="请输入关键词" :remote-method="classMethod" :loading="optionLoading">
              <el-option v-for="item in classOptions" :key="item.Id" :label="item.Name" :value="item.Name">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="班级教练：">
          <template>
            <el-select v-model="listQuery.TeacherName" filterable clearable remote reserve-keyword placeholder="请输入关键词" :remote-method="teacherMethod" :loading="optionLoading">
              <el-option v-for="item in teacherOptions" :key="item.Id" :label="item.Name" :value="item.Name">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration"> 查询 </el-button>
          <el-button v-preventReClick @click="resetSearch" class="plain-button"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体 -->
    <main class="page-main-main" ref="main">
      <!-- 列表渲染 -->
      <el-table v-loading="loading" :data="tableList" border fit stripe>
        <el-table-column label="通知创建时间" align="center" width="140px">
          <template slot-scope="{row}">
            {{ row.CreateTime && moment(row.CreateTime*1000).format('YYYY-MM-DD HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="CreateUser" label="创建人" align="center" :show-overflow-tooltip="true" />
        <el-table-column prop="Title" label="通知标题" align="center" :show-overflow-tooltip="true" />
        <el-table-column prop="Content" label="通知内容" align="center" :show-overflow-tooltip="true" width="280px" />
        <el-table-column prop="Class" label="接收班级" align="center" :show-overflow-tooltip="true" min-width="160">
          <template slot-scope="{ row }">
            {{ Array.isArray(row.Class) && row.Class.map(item=> item.ClubClassName).join('/') }}
          </template>
        </el-table-column>
        <el-table-column label="未读学员" align="center" width="80px">
          <template slot-scope="{row}">
            <span>{{row.AllRead-row.Read}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="Read" label="已读学员" align="center" width="80px" />
        <el-table-column label="操作" align="center" width="80px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="readDetail(row)"> 查看详情 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <footer>
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getNoticeList" />
      </footer>
    </main>
  </div>
</template>

<script>
import { getNoticeList, getAllClubClass, classNameFind, teacherNameFind } from "@/service/educational";
import { getClubTeacherList } from "@/service/organizational";
import pagination from '@/components/pagination/pagination.vue'; // 引入分页
import { deepClone } from '@/utils/index'; // 引入深拷贝
import moment from 'moment';

const ListQuery = { // 查询数据
  PageNo: 1,
  PageSize: 50,
  ClassName: '',
  TeacherName: '',
};
export default {
  name: "Attendance",
  components: {
    pagination,
  },
  data() {
    return {
      listQuery: deepClone(ListQuery),
      tableList: [],  // 表格数据
      total: 0, // 表格总条数
      loading: false, // 加载状态
      optionLoading: false,
      moment,
      classOptions: [],
      teacherOptions: [],
      classList: [],
      teacherList: [],
    }
  },
  created() {
    this.getNoticeList();
    this.classNameFind();
    this.teacherNameFind();
  },
  methods: {

    classMethod(query) {
      if (query && query.trim() !== '') {
        this.optionLoading = true;
        setTimeout(() => {
          this.optionLoading = false;
          this.classOptions = this.classList.filter(item => {
            return item.Name.toLowerCase()
              .indexOf(query.trim().toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.classOptions = this.classList;
      }
    },

    teacherMethod(query) {
      if (query && query.trim() !== '') {
        this.optionLoading = true;
        setTimeout(() => {
          this.optionLoading = false;
          this.teacherOptions = this.teacherList.filter(item => {
            return item.Name.toLowerCase()
              .indexOf(query.trim().toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.teacherOptions = this.teacherList;
      }
    },

    /**
     * 获取所有班级列表
     */
    async classNameFind() {
      let res = await classNameFind();
      this.classList = res.map((item, index) => {
        let obj = {};
        obj.Name = item;
        obj.Id = index + 1
        return obj;
      });
      this.classOptions = this.classList;
    },

    /**
      * 获取教练列表数据
      */
    async teacherNameFind() {
      let res = await teacherNameFind();
      this.teacherList = res.map((item, index) => {
        let obj = {};
        obj.Name = item;
        obj.Id = index + 1
        return obj;
      });
      this.teacherOptions = this.teacherList;
    },

    /**
      * 获取考勤管理列表数据
      */
    async getNoticeList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getNoticeList(this.listQuery);
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },

    /**
     * 页头-查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.ClassName = this.listQuery.ClassName && this.listQuery.ClassName.trim();
      this.listQuery.TeacherName = this.listQuery.TeacherName && this.listQuery.TeacherName.trim();
      this.classOptions = this.classList;
      this.teacherOptions = this.teacherList;
      this.getNoticeList();
    },

    /**
     * 页头-重置
     */
    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.classOptions = this.classList;
      this.teacherOptions = this.teacherList;
      this.getNoticeList();
    },

    /**
     * 操作-查看
     */
    readDetail(row) {
      this.$router.push({
        path: '/educational/notifiesDetail',
        query: {
          id: row.Id
        }
      });
    }
  },
}
</script>
<style lang='scss' scoped>
/deep/.el-table__body tr,
.el-table__body td {
  height: 54px !important;
}
</style>